<template>
  <v-form>
    <v-container fluid>
      <v-row>
        <v-col>
          <template
            v-for="item in parent
              ? resolveParents(parent)
              : item.parent
              ? resolveParents(item.parent)
              : []"
          >
            <a
              v-if="item.title"
              :key="item.title"
              :href="
                $router.resolve({
                  name: 'PageUpdate',
                  params: { id: item['@id'] },
                }).href
              "
            >{{ item.title }}/</a
            >
          </template>
          {{ item.title }}
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" sm="6">
          <v-text-field
            :error-messages="titleErrors"
            :label="$t('title')"
            @blur="$v.item.title.$touch()"
            @input="$v.item.title.$touch()"
            v-model="item.title"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" sm="6">
          <v-text-field
            :error-messages="metaTitleErrors"
            :label="$t('metaTitle')"
            @blur="$v.item.metaTitle.$touch()"
            @input="$v.item.metaTitle.$touch()"
            v-model="item.metaTitle"
          />
        </v-col>
        <v-col cols="12" md="6" sm="6">
          <v-text-field
            :error-messages="metaDescriptionErrors"
            :label="$t('metaDescription')"
            @blur="$v.item.metaDescription.$touch()"
            @input="$v.item.metaDescription.$touch()"
            v-model="item.metaDescription"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <InputEditorUpdated
            v-model="item.textContent"
            :error-messages="textContentErrors"
            :label="$t('textContent')"
            :required="true"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" sm="6">
          <v-checkbox
            :label="$t('enabled')"
            @blur="$v.item.enabled.$touch()"
            @input="$v.item.enabled.$touch()"
            v-model="item.enabled"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import {validationMixin} from "vuelidate";
import has from "lodash/has";
import {required} from "vuelidate/lib/validators";
//import InputEditor from "@/components/InputEditor";
import InputEditorUpdated from "@/components/InputEditorUpdated";
import PageMixin from "../../mixins/PageMixin";

export default {
  name: "PageForm",
  mixins: [PageMixin, validationMixin],
  components: {
    InputEditorUpdated,
    // InputEditor,
  },
  props: {
    values: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: () => {
      },
    },

    loading: {
      type: Boolean,
      required: true,
    },

    initialValues: {
      type: Object,
      default: () => {
      },
    },

    handleSubmit: {
      type: Function,
      required: false,
    },

    handleReset: {
      type: Function,
      required: false,
    },
    parent: {
      type: Object,
      required: false,
    },
  },
  mounted() {
  },
  data() {
    return {
      title: null,
      textContent: null,
      enabled: false,
      metaTitle: null,
      metaContent: null,
    };
  },
  computed: {
    item() {
      return this.initialValues || this.values;
    },
    titleErrors() {
      const errors = [];

      if (!this.$v.item.title.$dirty) {
        return errors;
      }

      has(this.violations, "title") && errors.push(this.violations.title);

      !this.$v.item.title.required && errors.push(this.$t("Field is required"));

      return errors;
    },
    metaTitleErrors() {
      const errors = [];

      if (!this.$v.item.metaTitle.$dirty) {
        return errors;
      }

      has(this.violations, "metaTitle") &&
      errors.push(this.violations.metaTitle);

      return errors;
    },
    metaDescriptionErrors() {
      const errors = [];

      if (!this.$v.item.metaDescription.$dirty) {
        return errors;
      }

      has(this.violations, "metaDescription") &&
      errors.push(this.violations.metaDescription);

      return errors;
    },
    textContentErrors() {
      const errors = [];

      if (!this.$v.item.textContent.$dirty) {
        return errors;
      }

      has(this.violations, "textContent") &&
      errors.push(this.violations.textContent);

      !this.$v.item.textContent.required &&
      errors.push(this.$t("Field is required"));

      return errors;
    },
    violations() {
      return this.errors || {};
    },
  },
  methods: {
    submitItem() {
      if (this.handleSubmit) {
        this.handleSubmit();
      }
    },
    resetItem() {
      if (this.handleReset) {
        this.handleReset();
      }
    },
    resolveParents(parent, path = []) {
      if (typeof parent === "string") {
        parent = this.resolvePage(parent);
      }
      path.push(
        parent.title ? {title: parent.title, "@id": parent["@id"]} : ""
      );

      return parent.parent
        ? this.resolveParents(parent.parent, path)
        : path.reverse();
    },
  },
  validations: {
    item: {
      title: {
        required,
      },
      textContent: {
        required,
      },
      metaTitle: {},
      metaDescription: {},
    },
  },
};
</script>
