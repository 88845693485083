<style>
.ProseMirror {
  min-height: 220px;
}
</style>
<template>
  <div>
    <vuetify-tiptap
      v-model="textInput"
      :label=label
      :config="config"
      :toolbar="toolbar"
      :dark="$vuetify.theme.dark"
      placeholder="Enter some text..."
      rounded
      :maxHeight="465"
    />
    <div class="mx-2 mt-3 mb-2" v-if="errorMessages.length > 0">
      <div class="v-messages error--text theme--light" role="alert">
        <div class="v-messages__wrapper">
          <div class="v-messages__message" v-for="(errorMessage, i) in errorMessages" :key="i">
            {{ errorMessage }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { VuetifyTiptap } from 'vuetify-pro-tiptap'
import SelectImage from './TipTapEditor/SelectImage.vue'
import DocumentMixin from "../mixins/DocumentMixin";

export default {
  components: {
    VuetifyTiptap,
  },
  mixins: [DocumentMixin],
  props: {
    label: {
      type: String,
      required: false,
      default: () => ''
    },
    value: String,
    required: {
      type: Boolean,
      required: false,
      default: () => false
    },
    errorMessages: {
      type: Array,
      required: false,
      default: () => []
    },
    blurFunction: {
      type: Function,
      required: false,
      default: () => {
      }
    },
    inputFunction: {
      type: Function,
      required: false,
      default: () => {
      }
    },
    disabled: {
      type: Boolean,
      required: false,
      default: () => false
    },
    simple: {
      type: Boolean,
      required: false,
      default: () => false
    },
  },
  data() {
    return {
      tipTapEditor: null,
      config: {
        image: {
          upload: this.uploadImage, // image upload function
          imageTabs: [{ name: 'Select', component: SelectImage }], // custom tab
          hiddenTabs: ['url'],
          allowBase64: true,
        }
      },
      toolbar: [
        'bold',
        'italic',
        'underline',
        'strike',
        '|',
        'h2',
        'h3',
        'h4',
        'h5',
        'p',
        '|',
        'left',
        'center',
        'right',
        '|',
        'bulletList',
        'orderedList',
        '|',
        'link',
        'image',
        '|',
        'rule',
        '|',
        'undo',
        'redo',
      ],
      dialog: false,
    }
  },
  computed: {
    textInput: {
      get: function () {
        return this.value;
      },
      set: function (newValue) {
        if (typeof newValue !== 'undefined' && newValue !== null && !newValue.replace(/<[^>]*>?/gm, '')) {
          // new value is empty, so set it to null.
          newValue = null;
        }
        this.$emit('input', newValue);
      }
    },
  },
  methods: {
    async uploadImage(file) {
      const uploadedFile = await this.uploadDocument(file, false, 1, 'cms');
      return Promise.resolve(`${ process.env.VUE_APP_API_ENTRYPOINT }${ uploadedFile.contentUrl }`)
    }
  },
  watch: {
    value(newValue) {
      this.textInput = newValue;
    },
  },
  created() {
    this.textInput = this.value;
  },
}
</script>
<style lang="scss" scoped>
.vuetify-pro-tiptap ::v-deep .image-view.image-view--inline .image-view__body__image {
  object-fit: scale-down;
}

::v-deep .vuetify-pro-tiptap-editor__menu-bubble .v-toolbar__content {
  > *:not(:nth-child(n+5)) {
    display: none;
  }
}
</style>
