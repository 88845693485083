import {mapActions, mapGetters} from "vuex";
import {mapFields} from "vuex-map-fields";

export default {
  data() {
    return {
      loadingMenuEntries: [],
    };
  },
  computed: {
    ...mapFields("page", {
      pageIds: "allIds",
      pageIdsCached: "cacheAllIds",
    }),
    ...mapGetters("page", {
      pageItems: 'list',
      findPage: "find",
    }),
  },
  methods: {
    ...mapActions("page", {
      retrievePage: "load",
      fetchAllMenuEntries: 'fetchAll',
      updatePage: "update",
      pageActions: "action",
    }),
    resolvePage(itemId) {
      if (!this.isPageLoaded(itemId) && !this.loadingMenuEntries.includes(itemId)) {
        this.loadingMenuEntries.push(itemId);
        this.retrievePage(decodeURIComponent(itemId));
      }
      return this.findPage(itemId);
    },
    resolveMenuEntries(itemIds) {
      if (typeof itemIds === "undefined") {
        return [];
      }
      return itemIds.map((itemId) => {
        return this.resolvePage(itemId);
      }).filter(e => e);
    },
    getPageItem(pageIri, type) {
      if (pageIri !== null) {
        let page = this.resolvePage(pageIri);
        if (page) {
          return page[type];
        }
      }
      return '';
    },
    isPageLoaded(itemId) {
      return this.pageIdsCached.includes(itemId);
    },
  }
};
